import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  //首页
  {  
    path: '/',  
    redirect: '/home',  
  },  
  //首页
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/index.vue'),
  },
    // 产品与服务
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/Product/index.vue')
  },
  //解决方案
  {
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/Solution/index.vue')
  },
  // 客户案例
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/views/Customer/index.vue')
  },
  // 生态合作
  {
    path: '/zoology',
    name: 'zoology',
    component: () => import('@/views/Zoology/index.vue')
  },
  // 关于我们
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutUs/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
