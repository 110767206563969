<template>
  <router-view/>
</template>

<style>
*{
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
